import React, {Component} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Image from 'react-bootstrap/Image';
import SearchBar from './SearchBar';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import HeatmapLayer from 'react-leaflet-heatmap-layer';
import { Map, TileLayer, Marker, Popup, GeoJSON } from 'react-leaflet';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";


import './styles.css';
import HeatMapRubbish from './HeatMapRubbish';
import Footer from './Footer';
import BackButton from './BackButton';
import MarkerCluster from './MarkerCluster';
import {fetchRubbish} from './api';


var L = window.L;

class HeatMapPage extends Component {
  constructor(props) {
    super(props);
    this.state = { points: [], r: [] };
  }

  render() {
    return (
      <div className="page">
        <BrowserView>
	<Row className="justify-content-md-center mt-5 mb-5">
        <Col xm={3} className="ml-3">
	  <BackButton />    
	</Col>
	<Col xs="auto">
	<div className="detail text-center">
          <div className="shadow-container">
	    <h2 className="mb-5">垃圾站</h2>
	      <HeatMapRubbish />
	    <div className="mb-5"><br/><br/><br/><br/></div>
	  </div>
	</div>
	</Col>
	<Col xm={3}>
	</Col>
	<br/>
	<br/>
	<br/>
	<br/>
	</Row>
        </BrowserView>
  <MobileView>
    <Row className="ml-2 mb-2 mt-2">
      <BackButton/>&nbsp;&nbsp;&nbsp;&nbsp;
      <h3 className="">垃圾站</h3>
    </Row>
    <Row>
      <Col>
        <HeatMapRubbish />
      </Col>
    </Row>
  </MobileView>

	<br/>
	<br/>
	<br/>
	<br/>
	<Footer />
      </div>
    );
  }
}

export default HeatMapPage;
